import { version } from './version';

export const environment = {
  BASE_URL: 'https://dtemongagua.giss.com.br/PlatformaDTe',
  production: false,
  product: 'dte',
  client: 'eicon',
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  powerbi: '3531100',
  schema: 'pbi_dte_mongagua',
  groupId: 'e581598b-3972-43c1-ac88-ecfed6ab510a',
  analiseGeral: '77980e35-2662-4630-bb50-c3e8fd1e90da',
  analiseUsuarios: 'e38e2fdb-523b-4acc-8f3e-1c206ea543f5',
  analiseComunicados: 'a575e457-f5b6-410b-87ba-1416778dd78f',
  analiseProcurador: 'eec547f1-44b0-4c31-88a9-8b24fadfd267',
  analiseEventos: '173f788d-e43a-42c7-afca-23c006f7f28c',
  analiseContribuintes: 'd44a01ee-9cfc-4d15-951b-436a070da76c'
};
